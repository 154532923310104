@import '@launchpad-ui/tokens/dist/media-queries.css';

.logoWithWordmarkHeader {
  margin: 0.9375rem 0;
  display: flex;
  align-items: center;
}

@media (--tablet) {
  .logoWithWordmarkHeader {
    margin: 0.9375rem 1.25rem;
  }
}

@media (--mobile) {
  .logoWithWordmarkHeader {
    padding: 0.625rem;
  }
}

.logoWithWordmarkHeader svg {
  fill: var(--lp-color-gray-950);
  height: 50px;
  width: 200px;
}

.logoWithWordmarkHeader a {
  display: inline-block;
}

.logoHeader {
  margin-left: 0.75rem;
}

/* Dark Theme */
.dark {
  margin: 0;
  padding: 0.625rem 1.25rem;
  background: var(--lp-color-gray-950);

  svg {
    fill: var(--lp-color-white-950);
  }
}

.dark.noBackground {
  background: var(--lp-color-gray-900);
}
