@import '@launchpad-ui/tokens/dist/media-queries.css';

.page {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 4.75rem);
  width: 100%;

  > * {
    flex: 1 0;
    width: 100%;
  }
}

.page > div:first-child,
.page > main:first-child,
.page > section:first-child {
  display: flex;
  flex-direction: column;
  z-index: calc(var(--stacking-above-new-context) + 1);

  :global(.Module-header) {
    width: 25rem;
    margin: 15vh auto 0;
    text-align: center;
  }

  :global(.Module-content) {
    height: fit-content;
    margin: 1.5rem auto;
    border-radius: 0.5rem;
    gap: var(--lp-size-18);
    display: flex;
    flex-direction: column;
  }

  :global(.Module) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (--desktop) {
  .page {
    flex-direction: row;

    > * {
      flex: 1 0 50%;
      width: 50%;
    }
  }
}

/* Dark Theme */
.dark {
  background: var(--lp-color-gray-900);
}

.dark > div:first-child,
.dark > main:first-child,
.dark > section:first-child {
  background: var(--lp-color-gray-900);
  opacity: 97%;
}

@media screen and (max-width: 480px) {
  :global(body) {
    width: 125%;
  }
}
