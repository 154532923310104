:root {
  --Form-flow-duration: 200ms;
}

.Form-group {
  margin: 1.25rem 0;
  padding: 0;
  border: none;
}

/* The margin for .Form-groupand the min-height of .Form-fieldError
  should be equal to avoid content shift when errors are shown */
.Form--increasedErrorMargin.has-a11y-updates .Form-group {
  margin: 1.75rem 0;
}

.Form .Form-group:first-child {
  margin-top: 0;
}

.Form .Form-group:last-child {
  margin-bottom: 0;
}

.Form--inline .Form-group {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
}

.Form--inline .Form-group + .Form-group,
.Form--inline .Form-group + [class*='_Button'] {
  margin-left: 0.625rem;
}

.Form-group label {
  display: flex;
  align-items: center;
  margin-bottom: 0.125rem;
}

.Form-group label + label {
  margin-top: 0.3125rem;
}

.Form-group.Default-TTL {
  font-size: var(--lp-font-size-300);
  color: var(--lp-color-text-ui-primary-base);
  font-weight: var(--lp-font-weight-medium);
}

.Form .Form-error {
  background: var(--lp-color-text-feedback-error);
  color: #fff;
  font-weight: var(--lp-font-weight-regular);
  padding: 0.4375rem 0.875rem;
  border-radius: var(--lp-border-radius-regular);
}

.Form-fieldError {
  color: var(--lp-color-text-feedback-error);
  font-size: var(--lp-font-size-200);
}

/* The margin for .Form-groupand the min-height of .Form-fieldError
  should be equal to avoid content shift when errors are shown */
.Form--increasedErrorMargin.has-a11y-updates .Form-fieldError {
  min-height: 1.75rem;
}

.Form:not(.Form--inline) .Form-fieldError {
  display: block;
  padding-top: 0.3125rem;
  text-align: left;
}

.Form--increasedErrorMargin:not(.Form--inline).has-a11y-updates .Form-fieldError {
  padding-top: 0.0625rem;
  padding-bottom: 0.3125rem;
}

::placeholder {
  color: var(--lp-color-text-field-placeholder);
}

.Form .is-invalid .Select-control,
.Form .is-invalid .CustomSelect > div,
.Form .is-invalid input {
  border-color: var(--lp-color-text-feedback-error);
}

/* flows */

.Form-flow {
  display: flex;
  position: relative;
}

.Form-flow > * {
  width: 100%;
  flex: 1 0 100%;
}

.form-enter {
  opacity: 0;
  transform: translateX(0%);
  transition:
    transform var(--Form-flow-duration),
    opacity var(--Form-flow-duration);
}

.form-enter.form-enter-active {
  opacity: 1;
  transform: translateX(-100%);
}

.form-leave {
  opacity: 1;
  transform: translateX(0%);
  transition:
    transform var(--Form-flow-duration),
    opacity var(--Form-flow-duration);
}

.form-leave.form-leave-active {
  opacity: 0;
  transform: translateX(-100%);
}

.Form--increasedErrorMargin.has-a11y-updates .Form-group.is-invalid {
  margin-bottom: 0;
}

.Form--increasedErrorMargin.has-a11y-updates .Form-group.is-invalid + .Form-group {
  margin-top: 0;
}

.Form--increasedErrorMargin.has-a11y-updates .Form-group.is-invalid + .Form-hint {
  margin-top: 0;
}

.FormInput {
  display: block;
  width: 100%;
  padding: 0.375rem 0.625rem;
  font-size: 0.8125rem;
  font-family: var(--lp-font-family-base);
  line-height: var(--lp-line-height-300);
  background-color: var(--lp-color-bg-field-base);
  border: 1px solid var(--lp-color-border-field-base);
  border-radius: var(--lp-border-radius-regular);
  color: var(--lp-color-text-ui-primary-base);
  transition: all 100ms linear;
}

.FormInput:placeholder-shown {
  overflow: hidden;
  text-overflow: ellipsis;
}

.FormInput.isDisabled,
input.FormInput:disabled,
select.FormInput:disabled,
input.FormInput:read-only {
  opacity: 1;
  border-color: var(--lp-color-border-field-disabled);
  background-color: var(--lp-color-bg-field-disabled);
  color: var(--lp-color-text-field-disabled);
}

.FormInput.isDisabled:hover,
.FormInput:disabled:hover {
  cursor: not-allowed;
}

textarea.FormInput {
  min-height: 2rem;
  height: auto;
  line-height: 1.125rem;
  resize: none;
}

textarea.FormInput:disabled,
textarea.FormInput:read-only {
  opacity: 1;
  color: var(--lp-color-text-field-disabled);
  background-color: var(--lp-color-bg-field-disabled);
  border-color: var(--lp-color-border-field-disabled);
}

input.FormInput::-webkit-autofill {
  box-shadow: 0 0 0 50px var(--lp-color-bg-interactive-secondary-base) inset;
}

.FormInput.isFocused,
.FormInput:focus {
  outline: 0;
  border-color: var(--lp-color-border-field-focus);
  box-shadow: 0 0 0 3px hsl(231.5 100% 62.5% / 0.1);
}

.FormInput[readonly],
.FormInput[readonly]:focus {
  color: var(--lp-color-text-ui-secondary);
  border-color: var(--lp-color-gray-500);
  box-shadow: none;
}

select.FormInput {
  appearance: none;
  background: transparent;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M7.41 8.84L12 13.42l4.59-4.58L18 10.25l-6 6-6-6 1.41-1.41z" fill="%23646f7a"/></svg>');
  background-size: 1.25rem;
  background-repeat: no-repeat;
  background-position:
    right 0.4em top 50%,
    0 0;
  padding-right: 1.25rem;
}

:root[data-type-refresh] {
  .Form-group.Default-TTL {
    font: var(--lp-text-body-1-regular);
  }

  .Form-fieldError {
    font: var(--lp-text-body-2-regular);
  }
}
