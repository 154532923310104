/* Make this invisible but fillable by teampassword, lastpass, etc */
.LoginForm-password:not(.is-required) {
  position: absolute;
  height: 0;
  margin: 0;
  overflow: hidden;
}

[class*='formIncreasedErrorMargin'].has-a11y-updates .LoginForm-password:not(.is-required) + fieldset {
  margin-top: 0;
}

/* Keep the forgot password link out of tab navigation when not visible */
.LoginForm-password:not(.is-required) .hint a {
  display: none;
}

.LoginForm--federal {
  width: 41.6875rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Login--federalWarning {
  margin-top: 0.625rem;
}

.LoginModule--federal {
  width: 100%;
}

button.LoginForm-submit {
  border-radius: 0;

  &:focus,
  &:focus-visible {
    border-radius: 0;
  }
}

.hint a {
  color: var(--lp-color-gray-200);
}
