.icon {
  &.first {
    margin-right: 0.15625rem;
  }

  &.last {
    margin-left: 0.15625rem;
  }
}

:global(.u-fs-sm) .icon {
  height: 0.875rem;
  margin-left: 0.0625rem;
  width: 0.875rem;
}
